import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DemoComponent } from 'src/app/pages/demo/demo.component';

@Injectable({
  providedIn: 'root'
})
export class CoinService {

  private modelRef: DemoComponent | null = null;

  constructor(private readonly modalService: NgbModal) {}

  open() {
    this.close();

    this.modelRef = DemoComponent.open(this.modalService);
  }

  close() {
    if (this.modelRef) {
      this.modelRef.close();

      this.modelRef = null;
    }
  }

}
