export enum apiRoutes {
  account = 'Account',
  login = 'Account/Login',
  signup = 'Account/SignUp',
  profitAdviserSignup = 'Account/SignUpAdviser',
  SecondSignUp = 'Account/SecondSignUp',
  ResetPassword = 'Account/ResetPassword',
  VerifyOtp = 'Account/VerifyOtp',
  ResendOtp = 'Account/ResendOtp',
  ForgetPassword = 'Account/ForgetPassword',
  verifyEmail = 'Account/verifyEmail',
  GetUserById = 'User/GetById',
  checkIfUriCorrect = 'Account/CheckUriCorrect',
  profileUpdate = 'user/UpdateProfile',
  userPasswordChange = 'user/ChangePassword',
  addLoanOfficer = 'user/addLoanOfficer',
  addEditProfitAdviser = 'user/AddUpdateProfitAdvisor',
  getLoanOfficer = 'User/GetById',
  unarchive = 'User/unarchive',
  profitArchives = 'User/profit/archive',
  getUserDetailByUserId = 'UserDetails/GetByUserId',
  getUserDetailsByUserId = 'UserDetails/GetUserDetailByUserId',

  CalandlySetUserEvent = 'Calandly/SetUserEvent',

  //assessment routes

  //assessment routes
  saveAssessment = 'Assessment',
  GetAssessmentById = 'Assessment/GetById',
  GetAssessmentUserEventList = 'Assessment/GetUserEventList',
  GetAssessmentByUserId = 'Assessment/GetByUserID',
  GetAssessmentPdfDetail = 'Assessment/GetAssessmentPdfDetail',
  deleteAssessement = 'Assessment',
  getAssessmentQuestionsById = 'AssessmentQuestion/GetByAssessmentId',
  GetAssessmentQuestionCategory = 'AssessmentQuestionCategory/',
  GetAllCategory = 'AssessmentQuestionCategory',
  addCategory = 'AssessmentQuestionCategory',

  getAllQuestions = 'AssessmentQuestionMaster',
  addQuestion = 'AssessmentQuestionMaster',
  editQuestion = 'AssessmentQuestionMaster',
  deleteQuestion = 'AssessmentQuestionMaster',
  getQuestionByCategory = 'AssessmentQuestionMaster/GetByAssessmentQuestionCategoryId',
  getQuestionWithCategory = 'AssessmentQuestionMaster/GetQuestionWithCategory',
  getAllProfitAdviser = 'User/GetAllProfitAdviser',
  deleteLoanOfficer = 'User',
  User = 'User',
  agentAssign = 'User/agent/assign',

  //Inquiry Routes
  getAllInquire = 'Inquiry',
  addInquiry = 'Inquiry',
  GetByEmailId = 'Inquiry/GetByEmailId',

  // FAQ Routes
  FAQ = 'FAQ',

  // profit course
  getAllProfitPlanCourseClasses = 'Courses/GetByIdCourseAllProfitPlan',
  getAllCourse = 'Courses/GetAllCourse',

  course = 'Courses',
  addCourse = 'Courses/AddCourse',
  updateCourse = 'Courses/UpdateSaveCourse',
  getCourseById = '/GetByIdCourse',
  deleteCourseAndPlan = '/DeleteCourseProfit',
  downloadCourseAndPlan = '/ExportExcelAllProfitPlan',

  DeleteProfitPlan = '/DeleteProfitPlan',
  // profit plan classes
  profitPlan = '',
  addprofitPlan = '/AddProfitPlan',
  resetPassword = 'Account/ResetPassword',

  //new API Routes
  preProfitSignUp = 'PreProfitPlan/SignUp',
  preProfitLogin = 'PreProfitPlan/Login',
  preForgotPassword = 'PreProfitPlan/ForgotPassword',
  preChangePassword = 'PreProfitPlan/ChangePassword',
  preGetUserById = 'PreProfitPlan/GetUserById',
  updateUserDetail = 'PreProfitPlan/UpdateUserDetails',
  createMortgageAssessment = 'PreProfitPlan/CreateMortgageAssessment',
  downloadExcel = 'PreProfitPlan/DownloadExcel',
  getMortgageAssessment = 'PreProfitPlan/GetMortgageAssessment',
  getUserList = 'PreProfitPlan/GetUserList',
  getAssessmentList = 'PreProfitPlan/GetAssessmentList',
  preProfitResetPassword = 'PreProfitPlan/ResetPassword',
  sendMail = 'PreProfitPlan/SendMail',
  searchTerm = 'PreProfitPlan/SearchTerm',
  exportCSV = 'PreProfitPlan/ExportUsersToExcel'
}
