import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {accountRoutes, appRoute} from 'src/app/Core/routes.enum';
import {Storage} from 'src/app/Core/storage';
import {UserRoleEnum} from 'src/app/Core/userRole.enum';
import {IUser} from 'src/app/Models/user.interface';
import {SideBarService} from 'src/app/shared/Services/sideBar.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  sideBarList: any[] = [];
  public userData: IUser = {} as IUser;
  logoPath = '/assets/images/navigation-logo.svg';

  constructor(private sideBarService: SideBarService, private router: Router) {
    if (environment.isAgent) {
      this.logoPath = '/assets/images/Mortagage_Agent_Logo_Black.svg';
    }
  }

  async ngOnInit() {
    this.userData = (await Storage.getUserData()) ?? ({} as IUser);
    if (this.userData.role === UserRoleEnum.ProfitAdviser) {
      this.sideBarList.push(
        {
          title: 'Assessment',
          link: '/' + appRoute.pages + '/' + appRoute.assessment,
          imgPath: '/assets/images/assessment-icon.svg'
        },
        {
          title: 'Inquiry',
          link: '/' + appRoute.pages + '/' + appRoute.inquiry,
          imgPath: '/assets/images/question-answer-fill.svg'
        },
        {
          title: 'Agent Profit Plan Classes',
          link: '/' + appRoute.pages + '/' + appRoute.agentProfitPlan,
          imgPath: '/assets/images/agentprofitplan.svg'
        },
        {
          title: 'Scripts and Calls',
          link: '/' + appRoute.pages + '/' + appRoute.agentProfitScript,
          imgPath: '/assets/images/phone-fill (1).svg'
        },
        {
          title: 'How to Use the Agent Profit Plan',
          link: '/' + appRoute.pages + '/' + appRoute.agentProfitUses,
          imgPath: '/assets/images/question-fill.svg'
        },
        {
          title: 'Weekly Plan Success',
          link: '/' + appRoute.pages + '/' + appRoute.weeklyPlans,
          imgPath: '/assets/images/calendar-fill.svg'
        }
      );
    } else if (this.userData.role == UserRoleEnum.Admin) {
      if (!environment.isAgent) {
        this.sideBarList.push(
          {
            title: 'Assessment',
            link: '/' + appRoute.pages + '/' + appRoute.assessment,
            imgPath: '/assets/images/assessment-icon.svg'
          },
          {
            title: 'Question Config',
            link: '/' + appRoute.pages + '/' + appRoute.questionConfig,
            imgPath: '/assets/images/menu-que-icon.svg'
          }
        );
      } else {
        this.sideBarList.push(
          {
            title: 'Profit Adviser',
            link: '/' + appRoute.pages + '/' + appRoute.profitofficer,
            imgPath: '/assets/images/officer.svg'
          },
          {
            title: 'Inquiry',
            link: '/' + appRoute.pages + '/' + appRoute.inquiry,
            imgPath: '/assets/images/question-answer-fill.svg'
          },
          // {
          //   title: 'PO Inquiry',
          //   link: '/' + appRoute.pages + '/' + appRoute.poInquiry,
          //   imgPath: '/assets/images/assessment-icon.svg'
          // },
          {
            title: 'Question Config',
            link: '/' + appRoute.pages + '/' + appRoute.questionConfig,
            imgPath: '/assets/images/menu-que-icon.svg'
          },
          {
            title: 'Agents',
            link: '/' + appRoute.pages + '/' + appRoute.agents,
            imgPath: '/assets/images/officer.svg'
          },
          {
            title: 'FAQ',
            link: '/' + appRoute.pages + '/' + appRoute.faq,
            imgPath: '/assets/images/faq.svg'
          },
          {
            title: 'Archive',
            link: '/' + appRoute.pages + '/' + appRoute.unarchive,
            imgPath: '/assets/images/archive.svg'
          },
          {
            title: 'Agent Profit Plan Classes',
            link: '/' + appRoute.pages + '/' + appRoute.agentProfitPlan,
            imgPath: '/assets/images/agentprofitplan.svg'
          },
          {
            title: 'Scripts and Calls',
            link: '/' + appRoute.pages + '/' + appRoute.agentProfitScript,
            imgPath: '/assets/images/phone-fill (1).svg'
          },
          {
            title: 'How to Use the Agent Profit Plan',
            link: '/' + appRoute.pages + '/' + appRoute.agentProfitUses,
            imgPath: '/assets/images/question-fill.svg'
          },
          {
            title: 'Weekly Plan Success',
            link: '/' + appRoute.pages + '/' + appRoute.weeklyPlans,
            imgPath: '/assets/images/calendar-fill.svg'
          }
        );
      }
    } else {
      this.sideBarList.push({
        title: 'Assessment',
        link: '/' + appRoute.pages + '/' + appRoute.assessment,
        imgPath: '/assets/images/assessment-icon.svg'
      });
    }
  }
  onLogoClick() {
    if (environment.isAgent) {
      this.router.navigateByUrl(appRoute.pages + '/' + appRoute.profitofficer);
    } else {
      this.router.navigateByUrl(appRoute.pages + '/' + appRoute.assessment);
    }
  }
  HeaderSidebarClose() {
    this.sideBarService.close();
  }
}
