import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {

  constructor() { }


  private modelRef: NgbModalRef = {} as NgbModalRef;

  config = {
    title: "Delete",
    message: "Are you sure want to delete this record ?"
  };

  ngOnInit(): void {

  }

  public static open(modalService: NgbModal, config: {
    title: string,
    message: string
  }) {
    let ref = modalService.open(DeleteConfirmationComponent);

    let component = ref.componentInstance as DeleteConfirmationComponent;

    component.modelRef = ref;
    component.config = config;

    return ref;
  }

  close(check: boolean) {
    this.modelRef.close(check);
  }

}


