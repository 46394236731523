//staging
export const environment = {
  production: true,
  baseApiUrl: 'https://themortgageprofitplan.com/api/',
  baseUrl: 'https://themortgageprofitplan.com/',
  assessmentPdfImgBaseUrl: 'https://themortgageprofitplan.com/',
  remoteImageUrl: 'https://themortgageprofitplan.com/',
  ProfitPlanImageUrl: 'https://theagentprofitplan.com//',
  courseimages: 'https://theagentprofitplan.com//',
  locationApi: 'https://api.ipregistry.co/?key=d58a1ngrtca7xv8e',
  mobileNoPattern: '000 000 0000',
  mobileNoPrefix: '+1 ',
  calandlyEvent: 'https://calendly.com/vishal-d1/30min',
  isAgent: false,
  adminUri: 'thetimdavis'
};
