<div class="assessment-topbar">
  <div class="mobile-header">
    <a class="mobile-menu" (click)="HeaderSidebarOpen()" href="javascript:void(0);"><img
        src="/assets/images/menu-icon.svg" /></a>
    <a class="mobile-logo v-align-middle-767">

      <p class="text-white f-20-700">MORTGAGE</p>
      <p class="text-color-pre f-20-700">PROFITPLAN</p>

      <!-- <img [src]="logoPath" alt="logo 1111" /> -->
    </a>
  </div>
  <div class="userinfo text-end">
    <img [src]="remoteImageUrl + userData?.profileImage"
      onerror="this.onerror=null;this.src='/assets/images/default.jpg';" />
    <div ngbDropdown class="d-inline-block" [autoClose]="autoClose">
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
        <span>{{ userData?.name || userData?.firstName + ' ' + userData?.lastName }}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="userHeaderClick('profile')">Profile</button>
        <!-- <button ngbDropdownItem (click)="userHeaderClick('password')">Change password</button> -->
        <button (click)="userHeaderClick('logout')" ngbDropdownItem>Logout</button>
      </div>
    </div>
  </div>
</div>