import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  @ViewChild('sectionHome') sectionHome: ElementRef | undefined;
  @ViewChild('sectionAboutUs') sectionAboutUs: ElementRef | undefined;
  @ViewChild('sectionPortfolio') sectionPortfolio: ElementRef | undefined;

  menuItems = [
    { text: 'Home', sectionName: 'sectionHome', isActive: true },
    { text: 'About', sectionName: 'sectionAboutUs', isActive: false },
    { text: 'Portfolio', sectionName: 'sectionPortfolio', isActive: false }
  ];
  isShowNavbar = false;

  constructor() { }

  ngOnInit() {

  }

  toggleNavbar() {
    this.isShowNavbar = !this.isShowNavbar;
  }

  changeSection(sectionName: any, index: number) {
    this.isShowNavbar = false;
    this.menuItems.forEach((el, i) => {
      if (i === index)
        el.isActive = true;
      else
        el.isActive = false;
    })
    switch (sectionName) {
      case 'sectionHome':
        this.sectionHome?.nativeElement.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'sectionAboutUs':
        this.sectionAboutUs?.nativeElement.scrollIntoView({
          behavior: 'smooth', block: 'center'
        });
        break;

      case 'sectionPortfolio':
        this.sectionPortfolio?.nativeElement.scrollIntoView({
          behavior: 'smooth',
        });
        break;
    }
  }

}
