<div class="agent-sign-up-wrapper">
  <div class="agent-sign-up-inner d-flex flex-wrap align-items-center">
    <div class="agent-sign-up-left">
      <div class="agent-sign-up-left-top">
        <img src="assets/images/agent-signup-logo.svg" />
      </div>
      <div class="agent-sign-up-left-bottom">
        <div #videoTemplate></div>

        <ng-container *ngIf="!videoUrl || videoUrl == ''">
          <img [src]="imageUrl" alt="img" onerror="this.src='/assets/images/agent-signup-bg.svg'" />
        </ng-container>
        <!-- <img src="assets/images/agent-signup-bg.svg" /> -->
      </div>
    </div>
    <div class="agent-sign-up-right">
      <div class="agent-sign-up-right-inner">
        <h2>Sign Up</h2>
        <div class="d-flex flex-wrap profit-advisor-top">
          <div class="d-flex flex-wrap profit-advisor-top-wrapper w-100">
            <div class="profit-advisor-left">
              <p><img src="assets/images/agent-headphone.svg" /> Your Profit Adviser:</p>
            </div>
            <div class="profit-advisor-right">
              <ul>
                <li>
                  <p>Name :</p>
                  <label> {{ profitAdviser?.name ?? '' | titlecase }} </label>
                </li>
                <li>
                  <p>Email :</p>
                  <label> {{ profitAdviser?.email ?? '' }} </label>
                </li>
                <li>
                  <p>Phone No. :</p>
                  <label> {{ profitAdviser?.mobileNo ?? '' }} </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <form [formGroup]="form">
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/agent-username.svg" />
            </span>
            <input formControlName="name" type="text" class="form-control" placeholder="Enter user name" />
            <div class="invalid-feedback"
              *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
              <div *ngIf="form.controls['name'].errors?.required">Please Enter name.</div>
            </div>
          </div>
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/agent-email.svg" />
            </span>
            <input formControlName="email" type="email" class="form-control" placeholder="Enter Email"
              (blur)="onEmailBlur()" />
            <div class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
              <div *ngIf="form.controls['email'].errors?.required">Please Enter email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please Enter valid email.</div>
            </div>
          </div>

          <div class="form-control-main phoneInput position-relative">
            <span>
              <img src="/assets/images/call-icon.svg" />
            </span>
            <ngx-intl-tel-input [cssClass]="'phoneInputCustom'" [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true" [searchCountryFlag]="true" [separateDialCode]="separateDialCode"
              [searchCountryField]="[SearchCountryField.All]" [preferredCountries]="preferredCountries"
              [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates" [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.National" name="mobileNo" formControlName="mobileNo">
            </ngx-intl-tel-input>
            <div class="invalid-feedback"
              *ngIf="form.controls['mobileNo'].invalid && form.controls['mobileNo'].touched">
              <div *ngIf="form.controls['mobileNo'].errors?.required; else errorShow">Please Enter mobile number.</div>
              <ng-template #errorShow>
                <div *ngIf="form.controls['mobileNo'].errors">Please Enter valid mobile number.</div>
              </ng-template>
            </div>
          </div>

          <!-- <div class="form-control-main position-relative">
            <span>
              <img src="assets/images/agent-phone.svg" />
            </span>
            <input
              type="text"
              formControlName="mobileNo"
              class="form-control"
              prefix="+1"
              placeholder="Enter phone number"
              [mask]="'(000) 000-0000'"
            />
          </div> -->
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/agent-country.svg" class="ImageRemix" />
            </span>
            <input formControlName="country" id="country" placeholder="please enter your Country" type="text"
              class="form-control" alphaNumeric />
            <div class="invalid-feedback"
              *ngIf="form.controls['country'].invalid && (form.controls['country'].dirty || form.controls['country'].touched)">
              <div *ngIf="form.controls['country'].errors?.required">Please Select country</div>
            </div>
          </div>
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/agent-state.svg" class="ImageRemix" />
            </span>
            <select class="form-control" id="state" [formControl]="state" name="state">
              <option value="" selected disabled>
                {{ form.controls['state'].status === 'DISABLED' ? 'No state available' : 'Select State' }}
              </option>
              <option *ngFor="let state of states" [value]="state">
                {{ state }}
              </option>
            </select>
            <div class="invalid-feedback"
              *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)">
              <div *ngIf="form.controls['state'].errors?.required">Please Select state</div>
            </div>
          </div>
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/agent-city.svg" class="ImageRemix" />
            </span>
            <input formControlName="city" id="city" placeholder="please enter your city" type="text"
              class="form-control" alphaNumeric appTrimInput />
            <div class="invalid-feedback"
              *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)">
              <div *ngIf="form.controls['city'].errors?.required">Please Enter city</div>
            </div>
          </div>
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/agent-pincode.svg" class="ImageRemix" />
            </span>
            <input formControlName="pinCode" type="text" class="form-control" placeholder="Enter your Zipcode" numberDir
              [MaxLength]="6" [setDefaultMaxNumber]="false" />
            <div class="invalid-feedback"
              *ngIf="form.controls['pinCode'].invalid && (form.controls['pinCode'].dirty || form.controls['pinCode'].touched)">
              <div *ngIf="form.controls['pinCode'].errors?.required">Please Enter zipcode</div>
            </div>
          </div>

          <ng-container *ngIf="isPasswords">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/agent-password.svg" />
              </span>
              <input formControlName="password" type="password" class="form-control"
                placeholder="Enter your password" />
              <div class="invalid-feedback"
                *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">
                <div *ngIf="form.controls['password'].errors?.required">Please Enter password.</div>
                <div *ngIf="form.controls['password'].errors?.minlength">Please Enter at least 8 character.</div>
              </div>
            </div>
          </ng-container>
          <div class="form-control-main position-relative address-input">
            <span>
              <img src="/assets/images/agent-address.svg" />
            </span>
            <input formControlName="address" type="text" class="form-control" placeholder="Enter your address" />
            <div class="invalid-feedback"
              *ngIf="form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)">
              <div *ngIf="form.controls['address'].errors?.required">Please Enter address.</div>
            </div>
          </div>
          <div
            class="form-control-main d-flex flex-wrap align-items-center justify-content-between address-input profit-signup">
            <p>Are you admin? <a (click)="onLoginClick()" href="javascript:void(0);">Login Here</a></p>
            <div class="sign-in-btn">
              <button (click)="onSubmit()">Sign Up</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>