import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-angular-img',
  templateUrl: './angular-img.component.html',
  styleUrls: ['./angular-img.component.css']
    
})
export class AngularImgComponent implements OnInit {


  ngOnInit() {
    // this.initparticles();
  }

  initparticles() {
    // this.bubbles();
    
 }

//  bubbles() {
//   $.each($(".particletext.bubbles"), function(){
//      var bubblecount = ($(this).width()/50)*10;
//      for(var i = 0; i <= bubblecount; i++) {
//         var size = ($.rnd(40,80)/10);
//         $(this).append('<span class="particle" style="top:' + $.rnd(20,80) + '%; left:' + $.rnd(0,95) + '%;width:' + '50' + 'px; height:' + '50' + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
//      }
//   });
// }
bubbles() {
  const bubbleContainers = document.querySelectorAll(".particletext.bubbles");
  
  bubbleContainers.forEach(container => {
    const bubblecount = (container.clientWidth / 50) * 10;
    
    for (let i = 0; i <= bubblecount; i++) {
      const top = Math.floor(Math.random() * 60) + 20; // Random top position between 20 and 80
      const left = Math.floor(Math.random() * 95); // Random left position between 0 and 95
      const delay = (Math.floor(Math.random() * 3) / 10).toFixed(1); // Random animation delay between 0 and 0.3
      
      const particle = document.createElement('span');
      particle.className = 'particle';
      particle.style.cssText = `
        top: ${top}%;
        left: ${left}%;
        width: 50px;
        height: 50px;
        animation-delay: ${delay}s;
      `;
      
      container.appendChild(particle);
    }
  });
}
 

}
