export enum UserRoleEnum {
  Admin = 1,
  User = 2,
  EventUser = 3,
  ProfitAdviser = 4,
  Agent = 5
}

export enum storageEnums {
  USER_ID = 'userId',
  ASSESSMENT_ID = 'assessmentId'
}
