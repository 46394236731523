import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PreloadAllModules, RouterModule, Routes, UrlSerializer } from '@angular/router';

import { AppComponent } from './app.component';
import { ThemeModule } from './theme/theme.module';
import { NgChartsModule } from 'ng2-charts';

import { SharedModule } from './shared/shared.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeadersInterceptor } from './shared/interceptor/headers.interceptor';
import { AuthGuardGuard } from './shared/guards/auth-guard.guard';
import { ScheduleEventGuard } from './shared/guards/schedule-event.guard';
import { accountRoutes, appRoute } from './Core/routes.enum';
import { NgxMaskModule } from 'ngx-mask';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './account/sign-up/sign-up.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LowerCaseUrlSerializer } from 'src/app/shared/Services/LowerCaseUrlSerializer';
import { UserRoleEnum } from './Core/userRole.enum';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './account/login/login.component';
import { AgentSignupComponent } from './account/agent-signup/agent-signup.component';
import { AgentLandingPageComponent } from './account/agent-landing-page/agent-landing-page.component';
import { LandingPageComponent } from './account/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule)
  },
  {
    path: appRoute.downloadPdf,
    loadChildren: () => import('./download-pdf/download-pdf.module').then((m) => m.DownloadPdfModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'assessment',
    loadChildren: () => import('./assessment/assessment.module').then((m) => m.AssessmentModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: appRoute.eventSchedule,
    loadChildren: () => import('./event-schedule/event-schedule.module').then((m) => m.EventScheduleModule),
    canActivate: [ScheduleEventGuard]
  },
  {
    path: ':uri',
    component: environment.isAgent ? AgentLandingPageComponent : LoginComponent,
    data: { role: environment.isAgent ? UserRoleEnum.Agent : UserRoleEnum.User, isEventUser: environment.isAgent ? true : false }
  },
  {
    path: ':uri/' + accountRoutes.signup,
    component: environment.isAgent ? AgentSignupComponent : SignUpComponent,
    data: { role: environment.isAgent ? UserRoleEnum.Agent : UserRoleEnum.User, isEventUser: false }
  },
  {
    path: '',
    // component: environment.isAgent ? LoginComponent : SignUpComponent,
    // data: { role: UserRoleEnum.User }
    component: LandingPageComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: environment.isAgent ? LoginComponent : SignUpComponent,
    pathMatch: 'full',
    data: { role: UserRoleEnum.User }
  }
];

// http://localhost:4200/downlaodpdf/f1abd562-afca-4e68-839f-08dabc00a0d3

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ThemeModule,
    NgChartsModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbModalModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
    NgxIntlTelInputModule,
    [NgbModule],
    NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
