<div class="navigation h-100">
  <div class="navigation-logo text-center" (click)="onLogoClick()">
    <!-- <img [src]="logoPath" alt="logo" /> -->
    <p class="text-white f-30-700">MORTGAGE</p>
    <p class="text-color-pre f-30-700">PROFITPLAN</p>
    <!-- <h2 class="f-42-700 text-white mb-70 f-25-767 mb-30-767">MORTGAGE<span class="text-color-pre f-42-700 f-25-767">PROFITPLAN</span></h2> -->
  </div>
  <nav>
    <div class="mobile-close">
      <a href="javascript:void(0);" (click)="HeaderSidebarClose()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            fill="rgba(255,255,255,1)"
          />
        </svg>
      </a>
    </div>
    <ul class="list-unstyled">
      <ng-container *ngFor="let data of sideBarList">
        <li>
          <a (click)="HeaderSidebarClose()" href="javascript:void(0);" [routerLink]="[data.link]">
            <img [src]="data.imgPath" />
            <span>{{ data.title }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
</div>
