import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
if (url.toLowerCase().includes('event')) {
  return super.parse(url.toLowerCase().toLowerCase());
} else if (url.includes('profit')) {
  if (url.includes('pages/assessment?profitAdviserId=')) {
    return super.parse(url);
  } 
  return super.parse(url.toLowerCase());
} else if (url.toLowerCase().includes('account')) {
  return super.parse(url.toLowerCase());
} else if (url.toLowerCase().includes('signup')) {
  return super.parse(url.toLowerCase());
}

    return super.parse(url);
  }
}

