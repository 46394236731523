<div class="model-popup-wrapper question-wrapper-popup">
  <div class="model-popup-inner">
    <div class="model-popup">
      <div class="model-popup-title d-flex">
        <h3>{{ config.title }}</h3>
        <button type="button" class="btn-close" (click)="close(false)" aria-label="Close"></button>
      </div>
      <div class="question-bottom-wrapper">
        <div class="question-bottom-inner">
          <p>{{ config.message }}</p>
          <div class="from-group text-end w-100 d-flex justify-content-end mt-5">
            <button class="added-btn me-1" type="button" (click)="close(false)">No</button>
            <button class="added-btn" type="submit" (click)="close(true)">
              Yes
            </button>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>