import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { zoomInOnEnterAnimation } from 'angular-animations';
@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css'],  
  animations: [
    zoomInOnEnterAnimation(),
  ]
})
export class DemoComponent implements OnInit {
  modalRef: NgbModalRef | null = null;
  animationState = false;
  animationWithState = false;

  constructor() { }

  ngOnInit() {
  }
  
  public static open(modalService: NgbModal) {
    let ref = modalService.open(DemoComponent, {
      modalDialogClass: 'LoaderModel'
    });

    let component = ref.componentInstance as DemoComponent;

    component.modalRef = ref;

    return component;
  }

  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  
  animate() {
    this.animationState = false;
    setTimeout(() => {
      this.animationState = true;
      this.animationWithState = !this.animationWithState;
    }, 1);
  }

}
