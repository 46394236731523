import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import {SubSink} from 'src/app/Core/SubSink';
import {accountRoutes} from 'src/app/Core/routes.enum';
import {UserRoleEnum} from 'src/app/Core/userRole.enum';
import {Inquiry} from 'src/app/Models/Inquiry';
import {IUser} from 'src/app/Models/user.interface';
import {AccountService} from 'src/app/services/account.service';
import {AddressServiceService} from 'src/app/services/address.service';
import {CommonFunction} from 'src/app/services/commonFunctions';
import {InquiryService} from 'src/app/services/inquiry.service';
import {ToastService} from 'src/app/services/toast.service';
import {LoaderService} from 'src/app/shared/Services/loader.service';
import {environment} from 'src/environments/environment';
import {Storage} from '../../Core/storage';

@Component({
  selector: 'app-agent-signup',
  templateUrl: './agent-signup.component.html',
  styleUrls: ['./agent-signup.component.css']
})
export class AgentSignupComponent implements OnInit {
  ngOnDestroy(): void {
    this.subSink.unSubscribe();
  }

  private subSink = new SubSink();

  @ViewChild('videoTemplate', {static: false}) videoTemplate: ElementRef<any> = {} as ElementRef<any>;

  profitAdviser: IUser | null = null;

  separateDialCode = true;

  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;

  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  PhoneNumberFormat = PhoneNumberFormat;
  countries: any;
  states: string[] = [];
  cities: string[] = [];

  private role: Number = UserRoleEnum.User;
  private isEventUser: boolean = false;
  public parentUri: string = '';

  logoPath = '/assets/images/signup-logo.svg';

  user: any = '';

  sideImage = '/assets/images/signin-bottom.svg';

  videoUrl = '';

  imageUrl = '/assets/images/signin-bottom.svg';

  constructor(
    private account: AccountService,
    private address: AddressServiceService,
    private toast: ToastService,
    private router: Router,
    private loader: LoaderService,
    routeSnapShot: ActivatedRoute,
    private inquiryService: InquiryService
  ) {
    this.countries = this.address.getCountries();

    let snapShot = routeSnapShot.snapshot;

    this.role = snapShot.data.role as Number;
    this.isEventUser = snapShot.data.isEventUser ? true : false;
    if (environment.isAgent) {
      this.logoPath = '/assets/images/Mortagage_Agent_Logo.svg';
    }
    if (this.role == UserRoleEnum.Agent) {
      this.parentUri = snapShot.paramMap.get('uri') ?? '';

      this.checkParentUri();
    }
  }

  isPasswords: boolean = true;
  showBaseImage: boolean = false;

  country = new FormControl('United States', [Validators.required]);

  state = new FormControl('', Validators.required);
  city = new FormControl('', Validators.required);

  form: any = '';

  ngOnInit(): void {
    Storage.removeUserData();

    this.setForm();

    this.checkCountryAndStateChanges();
  }

  private checkCountryAndStateChanges() {
    this.states = this.address.getStatesByCountry('US');
    this.country.disable();
  }

  private setForm() {
    this.isPasswords = true;
    this.form = new FormGroup({
      name: new FormControl('', [Validators.min(3), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobileNo: new FormControl(undefined, [Validators.required]),
      address: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', [Validators.required]),
      country: this.country,
      state: this.state,
      city: this.city,
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

  private checkParentUri() {
    if (this.parentUri == null || this.parentUri == '') {
      this.toast.error({
        title: '',
        message: 'Link Expired',
        timeout: 2000
      });

      this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
      return;
    }

    this.loader.open();

    this.account.checkIfUriCorrect(this.parentUri).subscribe({
      next: (value) => {
        this.loader.close();
        if (!value) {
          this.toast.error({
            title: '',
            message: 'Link Expired',
            timeout: 2000
          });

          this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
        } else {
          this.profitAdviser = value;
          if (this.profitAdviser?.signUpVideoUrl && this.profitAdviser?.signUpVideoUrl != '') {
            this.videoUrl = this.profitAdviser.signUpVideoUrl;
            var elm = document.createElement('iframe') as HTMLIFrameElement;
            elm.src = this.videoUrl;
            elm.width = '560';
            elm.height = '315';
            elm.title = 'YouTube video player';
            elm.frameBorder = '0';
            elm.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
            elm.allowFullscreen = true;
            elm.style.paddingTop = '20px';
            this.videoTemplate.nativeElement.appendChild(elm);
          } else if (this.profitAdviser?.signUpImageUrl) {
            this.imageUrl = environment.remoteImageUrl + this.profitAdviser.signUpImageUrl;
          } else {
            this.imageUrl = '';
            this.videoUrl = '';
          }
        }
      },
      error: (err) => {
        this.loader.close();
        this.toast.error({
          title: '',
          message: 'Link Expired',
          timeout: 2000
        });

        this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
      }
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    let req = {...this.form.value};
    req.state = req.state || '';
    req.city = req.city || '';
    req.country = 'United States';
    req.parentUri = this.parentUri;
    req.mobileNo = req?.mobileNo!['internationalNumber'];
    req.isEventUser = this.isEventUser;
    req.role = this.role;

    if (req.role == UserRoleEnum.ProfitAdviser) {
      req.parentUri = CommonFunction.removeSpecialCharacterFromString(req.parentUri);
    }

    this.loader.open();

    this.subSink.sink = this.account.signUp(req as IUser).subscribe({
      next: (data: any) => {
        this.loader.close();
        if (data) {
          if (this.role == UserRoleEnum.User) data.isEndUser = true;

          Storage.setUserData(data);
          if (this.isPasswords) {
            this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.agentWelcome + '?isUserPasswd=true');
          } else {
            this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.agentWelcome);
          }

          this.form.reset();
        } else {
          this.toast.error({
            message: 'Something went wrong',
            title: '',
            timeout: 2000
          });
        }
      },
      error: (error: any) => {
        this.loader.close();
        this.toast.error({
          message: error?.error?.errorMessage ?? 'Something went wrong',
          title: '',
          timeout: 2000
        });
      },
      complete: () => {
        this.loader.close();
      }
    });
  }

  onLoginClick() {
    this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
  }

  onEmailBlur() {
    let emailControl = this.form.controls['email'];
    if (emailControl.valid) {
      this.inquiryService.getByEmail(emailControl.value).subscribe({
        next: (data: Inquiry) => {
          if (data) {
            this.form.patchValue({
              name: data.name,
              email: data.email,
              mobileNo: data.mobileNo as any,
              address: data.address,
              country: 'United States',
              state: data.state,
              city: data.city,
              pinCode: data.pinCode
            });
          }
        },
        error: (data: any) => {}
      });
    }
  }

  ImageLoadError(error: any) {}
}
