import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AssessmentQuestionType } from 'src/app/Core/assessmentQuestionType.enum';
import { appRoute } from 'src/app/Core/routes.enum';
import { Storage } from 'src/app/Core/storage';
import { UserRoleEnum } from 'src/app/Core/userRole.enum';
import { IAssessmentQuestionCategory } from 'src/app/Models/IAssessmentQuestionCategory';
import { IAssessmentPdfProfitAdvisorDetail } from 'src/app/Models/IAssessmentUserInfo';
import { IAssessmentPdfDetail } from 'src/app/Models/assessment.interface';
import { AssessmentRevenueDetails } from 'src/app/Models/assessmentRevenueDetail.interface';
import { IAssessmentQuestion } from 'src/app/Models/question.interface';
import { IUser } from 'src/app/Models/user.interface';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-common-pdf-download',
  templateUrl: './common-pdf-download.component.html',
  styleUrls: ['./common-pdf-download.component.css']
})
export class CommonPdfDownloadComponent implements OnInit {
  @Input() isShow: boolean = false;
  chartConfig = {} as AssessmentRevenueDetails;
  public remoteImageUrl: string = environment.remoteImageUrl;
  answeredQuestionPer: number = 0;
  totalPerAndUnit = {
    totalPer: 0,
    totalUnits: 0
  };
  isHidden = true;

  revenueDetail = {
    units: 0,
    hours: 0,
    currentVolume: 0,
    currentIncome: 0,
    averageCommission: 0,
    numberOfClient: 0,
    // hourWorked: 0,
    numberOfListings: 0,
    numberOfBuyers: 0,
    averageSalesPrice: 0,
    homeSold: 0
  } as AssessmentRevenueDetails;

  profitAdvisor: IAssessmentPdfProfitAdvisorDetail | null = null;

  AssessmentQuestionType = AssessmentQuestionType;
  assessmentQuestionList: any = [];

  pdfDetail: IAssessmentPdfDetail | null = null;
  userData: any = '';
  currentDate = new Date();
  baseUrl: string = environment.assessmentPdfImgBaseUrl;
  isAgent = false;
  isAgentOrProfit = false;
  isVisibleButton = false;
  arrOne: any = {
    numbers: [0, 0, 0, 0, 0],
    labels: ['Very Bad', 'Bad', 'Good', 'Very Good', 'Great', ''],
    colors: ['#FEE9DA', '#FDD4B9', '#FDC097', '#FCA265', '#FA7A23', '#FAFAFA']
  };
  doughnutCharts: any = [this.arrOne];
  @Input() set download(value: IAssessmentPdfDetail | null) {
    if (value) {
      this.isHidden = false;
      this.SetPdfDetail(value);
      setTimeout(() => {
        this.print();
      }, 1000);
    }
  }

  @Input() set populate(value: IAssessmentPdfDetail | null) {
    this.isHidden = false;
    document.getElementById('pdfPage')?.classList.remove('pdfPageClass');
    if (value) {
      this.SetPdfDetail(value);
    }
  }

  @ViewChild('pdfContent', {static: false}) pdfContentRef: ElementRef | any;
  @ViewChild('pdf-top-left', {static: false}) doughnutChartCanvas: ElementRef | any;
  @ViewChild('Income1', {static: false}) Income1: ElementRef | any;
  @ViewChild('Income2', {static: false}) Income2: ElementRef | any;
  @ViewChild('Unit1', {static: false}) Unit1: ElementRef | any;

  @ViewChild('Unit2', {static: false}) Unit2: ElementRef | any;
  @ViewChild('Volume1', {static: false}) Volume1: ElementRef | any;
  @ViewChild('Volume2', {static: false}) Volume2: ElementRef | any;

  constructor(private route: Router, private routes: ActivatedRoute) {
    if (environment.isAgent) {
      this.isAgent = true;
    }
    (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  async ngOnInit() {
    this.userData = (await Storage.getUserData()) ?? ({} as IUser);
    if (this.userData.role == UserRoleEnum.ProfitAdviser || this.userData.role == UserRoleEnum.Agent || environment.isAgent) {
      this.isAgentOrProfit = true;
    }
    this.routes.queryParams.subscribe((params) => {
      if (params['IsVisible']) {
        this.isVisibleButton = true;
      } else {
        this.isVisibleButton = false;
      }
    });
  }
  backClicked() {
    this.route.navigateByUrl(appRoute.pages + '/' + appRoute.assessment);
  }
  private async SetPdfDetail(value: IAssessmentPdfDetail) {
    this.isHidden = false;

    this.revenueDetail.units = value?.basePoints;
    this.revenueDetail.hours = value?.workingHours || 2000;
    this.revenueDetail.currentIncome = value?.currentIncome || 0;
    this.revenueDetail.currentVolume = value?.currentVolume || 0;
    this.revenueDetail.averageCommission = value?.averageCommission || 0;
    this.revenueDetail.averageSalesPrice = value?.averageSalesPrice || 0;
    this.revenueDetail.homeSold = value?.homeSold || 0;
    // this.revenueDetail.hourWorked = value.hourWorked || 0;
    this.revenueDetail.numberOfBuyers = value?.numberOfBuyers || 0;
    this.revenueDetail.numberOfClient = value?.numberOfClient || 0;
    this.revenueDetail.numberOfListings = value?.numberOfListings || 0;
    let answeredQuestionLength =
      value?.answeredQuestionsByCategory?.length &&
      value?.answeredQuestionsByCategory?.map((x) => x?.questions?.length).reduce((a: number, b: number) => a + b);
    var per = (answeredQuestionLength / value?.totalQuestions) * 100;
    this.answeredQuestionPer = per;
    this.assessmentQuestionList = value?.answeredQuestionsByCategory;
    this.pdfDetail = value;

    let allQuestions: IAssessmentQuestion[] = [];

    this.pdfDetail?.answeredQuestionsByCategory?.length &&
      this.pdfDetail?.answeredQuestionsByCategory?.forEach((x) => {
        allQuestions.push(...x.questions);
      });

    let perQuestion = allQuestions?.filter((x) => x?.isPercentage);
    let unitQuestion = allQuestions?.filter((x) => !x?.isPercentage);

    let totalPer = 0;
    let totalUnit = 0;

    this.profitAdvisor = value?.profitAdviser;

    if (perQuestion && perQuestion?.length > 0) {
      totalPer = perQuestion?.map((x) => x?.percentage)?.reduce((a, b) => a + b);
    }

    if (unitQuestion && unitQuestion?.length > 0) {
      totalUnit = unitQuestion?.map((x) => x?.unit)?.reduce((a, b) => a + b);
    }

    this.totalPerAndUnit = {
      totalPer: totalPer,
      totalUnits: totalUnit
    };
    this.revenueDetail.totalPer = this.totalPerAndUnit?.totalPer || 0;

    this.revenueDetail.totalUnits = this.totalPerAndUnit?.totalUnits || 0;

    this.chartConfig = JSON.parse(JSON.stringify(this?.revenueDetail));
  }
  getAssessmentCategoryText(item: IAssessmentQuestion) {
    if (item.assessmentQuestionTypeId == AssessmentQuestionType.Yes) {
      return 'Yes';
    } else if (item.assessmentQuestionTypeId == AssessmentQuestionType.No) {
      return 'No';
    } else {
      return 'Other';
    }
  }

  private getAnsweredQuestionCount(category: IAssessmentQuestionCategory[]) {
    var count = category.map((x) => x.questions.length).length;

    return count;
  }

  tableLayoutHeader = {
    hLineColor: function (i: any, node: any) {
      return 'black';

      // return i === 0 || i === node.table.body.length ? 'white' : 'white';
    },
    vLineColor: function (i: any, node: any) {
      return i === 0 || i === node.table.widths.length ? 'black' : 'white';
    },
    paddingLeft: function (i: any, node: any) {
      return 5;
    },
    paddingRight: function (i: any, node: any) {
      return 5;
    },
    paddingTop: function (i: any, node: any) {
      return 2;
    },
    paddingBottom: function (i: any, node: any) {
      return 2;
    }
  };

  tableLayoutQuestion = {
    hLineColor: function (i: any, node: any) {
      return 'gray';
    },
    vLineColor: function (i: any, node: any) {
      return i === 0 || i === node.table.widths.length ? 'black' : 'white';
    },
    paddingLeft: function (i: any, node: any) {
      return 5;
    },
    paddingRight: function (i: any, node: any) {
      return 5;
    },
    paddingTop: function (i: any, node: any) {
      return 2;
    },
    paddingBottom: function (i: any, node: any) {
      return 2;
    }
  };

  tableLayoutCat = {
    hLineColor: function (i: any, node: any) {
      return 'black';
    },
    vLineColor: function (i: any, node: any) {
      return i === 0 || i === node.table.widths.length ? 'black' : 'white';
    },
    paddingLeft: function (i: any, node: any) {
      return 5;
    },
    paddingRight: function (i: any, node: any) {
      return 5;
    },
    paddingTop: function (i: any, node: any) {
      return 2;
    },
    paddingBottom: function (i: any, node: any) {
      return 2;
    }
  };

  //pdfContentRef
  capturedImage: any = '';
  docDefinition: any = () => {
    return {
      pageSize: 'A4',
      content: [
        {
          columns: [
            {
              margin: [0, 0, 50, 0],
              stack: [
                this.isAgentOrProfit
                  ? {
                      image: this.capturedImage,
                      width: 500,
                      height: 750,
                      style: 'logo'
                    }
                  : {
                      image: this.capturedImage,
                      width: 500,
                      style: 'logo'
                    }
              ]
            }
          ]
        },

        ...this.tableData(),
        '\n'
      ],
      footer: {
        // text: [{text: 'Report by Mortgage Profit Plan', style: 'strong'}],
        // alignment: 'center'
        margin: [40, 0, 45, 0],
        fillColor: '#2c5c84',
        layout: 'noBorders',
        table: {
          widths: [520, 100, 100],
          body: [
            [
              {
                text: 'Report by Mortgage Profit Plan',
                alignment: 'center',
                color: 'white',
                bold: true,
                lineHeight: 1.2
              }
            ]
          ]
        }
      },
      styles: {
        header: {
          fontSize: 20,
          color: '#000',
          alignment: 'right'
          // margin: [0, 0, 0, 0],
        },
        subheader: {
          fontSize: 16,
          color: '#000'
          // margin: [0, 0, 0, 5],
        },
        strong: {
          bold: true,
          color: '#333'
        },
        logo: {
          margin: [0, 0, 0, 30]
        }
      },
      defaultStyle: {
        fontSize: 10,
        lineHeight: 1.5

        // color: '#676767',
      }
    };
  };

  tableData(): Array<any> {
    let resultFormat: any[] = [];
    if (this.assessmentQuestionList.length > 0) {
      const headers = {
        layout: this.tableLayoutHeader, // optional
        table: {
          headerRows: 1,
          widths: [290, 100, 100],
          body: [
            [
              {
                text: 'Question',
                color: 'black',
                fontSize: 10,
                height: 5,
                marginTop: 6
              },
              {text: 'Answer', color: 'black', fontSize: 10, marginTop: 6},
              {
                text: 'Units',
                color: 'black',
                fontSize: 10,
                marginTop: 6
              }
            ]
          ]
        }
      };
      resultFormat.push(headers);
      this.assessmentQuestionList.forEach((data: any) => {
        const headersCat = {
          layout: this.tableLayoutCat, // optional
          table: {
            widths: [290, 100, 100],
            body: [
              [
                {
                  text: data.name,
                  fontSize: 12,
                  Color: 'black',
                  fontWeight: 'bold'
                },
                ' ',
                ' '
              ]
            ]
          }
        };

        resultFormat.push(headersCat);

        const headers = {
          layout: this.tableLayoutQuestion, // optional
          table: {
            widths: [290, 100, 100],
            body: [...this.SectionDataDisplay(data.questions)]
          }
        };
        resultFormat.push(headers);
      });
      return resultFormat;
    }
    return resultFormat;
  }

  SectionDataDisplay(questions: any): Array<any> {
    let resultFormat: any[] = [];
    questions.forEach((data: IAssessmentQuestion) => {
      resultFormat.push([
        {
          text: `Q. ${data.question}` + '\n' + `${data.answer ? `A. ${data.answer}` : ''}`
        },
        {
          text:
            data.assessmentQuestionTypeId == AssessmentQuestionType.Yes
              ? 'yes'
              : data.assessmentQuestionTypeId == AssessmentQuestionType.No
              ? 'no'
              : 'other',
          style:
            data.assessmentQuestionTypeId == AssessmentQuestionType.Yes
              ? {color: '#fa7a23'}
              : data.assessmentQuestionTypeId == AssessmentQuestionType.No
              ? {color: '#ff2222'}
              : {color: '#2c5c84'}
        },
        {text: data.isPercentage ? data.percentage + '%' : data.unit}
      ]);
    });
    return resultFormat;
  }

  async print() {
    var win = window.open('', '_blank');
    const portalDiv: any = document.querySelector('#capture');
    await html2canvas(portalDiv, {
      logging: true,
      useCORS: true,
      allowTaint: false
    }).then((canvas: any) => {
      this.capturedImage = canvas.toDataURL();
      canvas.toBlob(function (blob: any) {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
        };
      });
    });
    const pdfDocGenerator = await pdfMake.createPdf(this.docDefinition()).open({}, win);
    if (!this.isVisibleButton) this.isHidden = true;
  }
}
