<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ config.title }}</h5>
        <button type="button" (click)="close(false)" class="close btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ config.message }}</p>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" (click)="close(false)" class="btn btn-secondary float-right" data-dismiss="modal">No</button>
        <button type="button" (click)="close(true)" class="btn btn-primary" ng-click="deleteRecord()">Yes</button>
    </div>
</div>