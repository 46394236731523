<div class="landing-wrapper">
  <div class="hedaer-top">
    <div class="fixed-container">
      <header class="d-flex flex-wrap align-items-center justify-content-between {{ isMenuOpen ? 'nav-open' : '' }}">
        <div class="mobile-menu" (click)="onMenuClick()">
          <a href="javascript:void(0);">
            <img loading="lazy" src="/assets/images/landingpage/menu-fill.svg" />
          </a>
        </div>
        <div class="header-logo">
          <img loading="lazy" src="/assets/images/landingpage/landing-logo.svg" />
        </div>
        <div class="navigation">
          <nav>
            <ul class="d-flex flex-wrap">
              <li><a href="javascript:void(0);'">Home</a></li>
              <li><a href="javascript:void(0);'" (click)="onWhyUsClick()">Why Us</a></li>
              <li><a href="javascript:void(0);'" (click)="onAboutUsClick()">About Us</a></li>
            </ul>
          </nav>
        </div>
        <div class="signup-header navigation">
          <ul class="d-flex flex-wrap">
            <li><a href="javascript:void(0);" (click)="onLoginClick()" class="">Login</a></li>
            <li><a href="javascript:void(0);" (click)="onSignUpClick()" class="landing-btn">SignUp</a></li>
          </ul>
          <!-- <a href="javascript:void(0);" (click)="onLoginClick()" class="">Login</a> -->
        </div>
      </header>
    </div>
  </div>
  <div class="main-banner">
    <div class="fixed-container">
      <div class="main-banner-wrapper d-flex flex-wrap align-items-center">
        <div class="main-banner-left">
          <h1>Real Estate Agents: Let Us Show You How to Close an Extra 5-10 homes per year - <span>Guaranteed</span></h1>
          <p class="w-97">
            The Agent Profit Plan, a cutting-edge system designed for real estate agents, Provides agents with a simple plan to grow their
            business and sell 5-10 homes or more per year.
          </p>
          <a href="javascript:void(0);" (click)="onContactNowClick()" class="landing-btn">Contact Now</a>
        </div>
        <div class="main-banner-right">
          <img loading="lazy" src="/assets/images/landingpage/landing-banner.svg" />
        </div>
      </div>
    </div>
  </div>
  <div class="landing-logo-wrapper">
    <div class="fixed-container d-flex flex-wrap align-items-center justify-content-end">
      <p>As seen On:</p>
      <div class="landing-logo-inner d-flex flex-wrap align-items-center">
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo1.svg" />
        </div>
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo2.svg" />
        </div>
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo3.svg" />
        </div>
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo4.svg" />
        </div>
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo5.svg" />
        </div>
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo6.svg" />
        </div>
        <div class="landing-logo-box">
          <img loading="lazy" src="/assets/images/landingpage/see-on-logo7.svg" />
        </div>
      </div>
    </div>
  </div>
  <div class="landing-client-detail">
    <div class="fixed-container">
      <div class="d-flex flex-wrap align-items-center">
        <div class="landing-client-detail-left d-flex flex-wrap align-items-center">
          <div class="client-detail-left-img">
            <!-- <img loading="lazy" src="/assets/images/landingpage/landing-user.png" /> -->
            <img
              loading="lazy"
              [src]="baseImageUrl + profitAdviser?.userImage"
              onerror="this.onerror=null;this.src='/assets/images/default.jpg';"
            />
          </div>
          <div class="client-detail-left-content">
            <label>Your profit advisor</label>
            <h2>{{ profitAdviser?.name | titlecase }}</h2>
            <a href="mailto:{{ profitAdviser?.email }}"
              ><img loading="lazy" src="/assets/images/landingpage/Frame (1).svg" />{{ profitAdviser?.email }}</a
            >
            <a href="tel:{{ profitAdviser?.mobileNo }}"
              ><img loading="lazy" src="/assets/images/landingpage/Frame.svg" />{{ profitAdviser?.mobileNo }}</a
            >
          </div>
        </div>
        <div class="landing-client-detail-right">
          <p>
            {{ profitAdviser?.landingContent ?? '' }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="landing-signup-container" id="contact-now">
    <div class="fixed-container">
      <div class="signup-form">
        <div class="signup-title">
          <h2>Request an Assessment for You or Your Office</h2>
        </div>
        <form [formGroup]="form" class="signup-input">
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/landingpage/user.svg" />
            <input formControlName="name" type="text" placeholder="Enter user name"/>
            <div
              class="invalid-feedback"
              *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)"
            >
              <div *ngIf="form.controls['name'].errors?.required">Please Enter name.</div>
            </div>
          </div>
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/landingpage/email.svg" />
            <input formControlName="email" type="email" placeholder="Enter email"/>
            <div
              class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
            >
              <div *ngIf="form.controls['email'].errors?.required">Please Enter email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please Enter valid email.</div>
            </div>
          </div>
          <div class="signup-input-div phoneInput">
            <ngx-intl-tel-input
              [cssClass]="'agentLandingPhoneInput'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [separateDialCode]="separateDialCode"
              [searchCountryField]="[SearchCountryField.All]"
              [preferredCountries]="preferredCountries"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.UnitedStates"
              [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.National"
              name="mobileNo"
              formControlName="mobileNo"
            >
            </ngx-intl-tel-input>
            <div class="invalid-feedback" *ngIf="form.controls['mobileNo'].invalid && form.controls['mobileNo'].touched">
              <div *ngIf="form.controls['mobileNo'].errors?.required; else errorShow">Please Enter mobile number.</div>
              <ng-template #errorShow>
                <div *ngIf="form.controls['mobileNo'].errors">Please Enter valid mobile number.</div>
              </ng-template>
            </div>
          </div>
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/landingpage/country.svg" />
            <input
              formControlName="country"
              id="country"
              placeholder="please enter your Country"
              type="text"
              alphaNumeric
            />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['country'].invalid && (form.controls['country'].dirty || form.controls['country'].touched)"
            >
              <div *ngIf="form.controls['country'].errors?.required">Please Select country</div>
            </div>
          </div>
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/landingpage/state.svg" />
            <select id="state" [formControl]="state" name="state">
              <option value="" selected disabled>
                {{ form.controls['state'].status === 'DISABLED' ? 'No state available' : 'Select State' }}
              </option>
              <option *ngFor="let state of states" [value]="state">
                {{ state }}
              </option>
            </select>
            <div
              class="invalid-feedback"
              *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)"
            >
              <div *ngIf="form.controls['state'].errors?.required">Please Select state</div>
            </div>
          </div>
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/landingpage/city.svg" />
            <input formControlName="city" id="city" placeholder="please enter your city" type="text" alphaNumeric />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)"
            >
              <div *ngIf="form.controls['city'].errors?.required">Please Enter City</div>
            </div>
          </div>
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/agent-pincode.svg" />
            <input
              formControlName="pinCode"
              type="number"
              placeholder="Enter your zipcode"
              numberDir
              [MaxLength]="6"
              [setDefaultMaxNumber]="false"
            />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['pinCode'].invalid && (form.controls['pinCode'].dirty || form.controls['pinCode'].touched)"
            >
              <div *ngIf="form.controls['pinCode'].errors?.required">Please Enter zipcode</div>
            </div>
          </div>
          <div class="signup-input-div">
            <img loading="lazy" src="/assets/images/landingpage/location.svg" />
            <input formControlName="address" type="text" placeholder="Enter your address" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)"
            >
              <div *ngIf="form.controls['address'].errors?.required">Please Enter address.</div>
            </div>
          </div>
          <div class="admin-login-container">
            <!-- <p>
              Are you admin? <a (click)="onLoginClick()" href="javascript:void(0);"><span>Login Here</span></a>
            </p> -->
            <button type="submit" class="sign-up-button" (click)="onSubmitInquiry()">Submit</button>
          </div>
        </form>
      </div>
      <div class="signup-img">
        <div #videoTemplate></div>

        <ng-container *ngIf="!videoUrl || videoUrl == ''">
          <img [src]="imageUrl" alt="img" onerror="this.src='/assets/images/landingpage/landing-banner.svg'" />
        </ng-container>
      </div>
    </div>
  </div>
  <div class="landing-section1-container" id="whyUsSection">
    <div class="fixed-container">
      <div class="landing-left-section-container">
        <img loading="lazy" src="/assets/images/landingpage/agentLandingSecondSection.png" alt="" />
      </div>
      <div class="landing-right-section-container">
        <p>
          The Agent profit plan helped me to realize I could more than double my business by focusing on targeted planning, action and
          follow up activities as well as developing some new habits. It answered the "what can I do to increase my business question" with
          a personalized report based on my current business performance. I recommend this for every real estate broker who wants to move
          their business forward." Ingrid Conley, BIC, Realty One
        </p>
        <div class="section1-div-container">
          <div class="box-container">
            <div class="box">
              <p>
                <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="134" height="134" stroke="#FA7A23" stroke-width="2" />
                  <path
                    d="M103.495 68.0001C103.495 68.0001 89.3079 50.8792 68.0001 50.8792C46.6923 50.8792 32.5056 68.0001 32.5056 68.0001C32.5056 68.0001 46.6923 85.121 68.0001 85.121C89.3079 85.121 103.495 68.0001 103.495 68.0001Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M50.583 67.8692C50.583 77.4225 58.3277 85.1672 67.881 85.1672C77.4343 85.1672 85.179 77.4225 85.179 67.8692C85.179 58.3159 77.4232 50.5823 67.881 50.5823C58.3277 50.5823 50.583 58.327 50.583 67.8692Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M62.5546 67.8691C62.5546 70.8094 64.9401 73.195 67.8804 73.195C70.8208 73.195 73.2063 70.8094 73.2063 67.8691C73.2063 64.9288 70.8208 62.5432 67.8804 62.5432C64.9401 62.5432 62.5546 64.9288 62.5546 67.8691Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M67.8801 32.3634V37.545"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M50.5828 35.6218L53.5342 40.8035"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M85.1665 35.6218L82.2151 40.8035"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M67.8801 103.375V98.1934"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M85.1665 100.116L82.2151 94.9459"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M50.5828 100.116L53.5342 94.9347"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="13.3333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
            </div>
            <div class="box-content">
              <p>Clarity</p>
            </div>
          </div>
          <div class="box-container">
            <div class="box">
              <p>
                <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_930_1124" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="76" height="76">
                    <path d="M76 -3.05176e-05H-3.05176e-05V76H76V-3.05176e-05Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_930_1124)">
                    <path
                      d="M2.22633 27.6724C2.22633 41.726 13.6186 53.1198 27.6722 53.1198C33.012 53.1198 37.9679 51.4742 42.0618 48.6619C44.647 46.887 46.8869 44.6471 48.6616 42.0619C51.4739 37.968 53.1196 33.0123 53.1196 27.6724C53.1196 13.6187 41.7259 2.22645 27.6722 2.22645C13.6186 2.22645 2.22633 13.6187 2.22633 27.6724Z"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M44.2136 27.6732C44.2136 18.5383 36.8079 11.1328 27.6731 11.1328"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M67.4757 73.7734L73.7734 67.4758L56.2678 49.9701L49.9701 56.2678L67.4757 73.7734Z"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M45.6654 45.6655L53.1184 53.12"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </p>
            </div>
            <div class="box-content">
              <p>Focus</p>
            </div>
          </div>
          <div class="box-container">
            <div class="box">
              <p>
                <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="134" height="134" stroke="#FA7A23" stroke-width="2" />
                  <mask id="mask0_0_1" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="30" y="30" width="76" height="76">
                    <path d="M30 30H106V106H30V30Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_0_1)">
                    <path
                      d="M68 85.9609C68 95.7985 60.0251 103.773 50.1875 103.773C40.35 103.773 32.375 95.7985 32.375 85.9609C32.375 76.1234 40.35 68.1484 50.1875 68.1484C60.0251 68.1484 68 76.1234 68 85.9609Z"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M50.1875 103.773H99.1719C101.631 103.773 103.625 101.78 103.625 99.3203V47.0707L88.7819 32.2265H54.6406C52.1813 32.2265 50.1875 34.2203 50.1875 36.6797V68.1484"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M88.7812 32.2266V47.0703H103.625L88.7812 32.2266Z"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M61.9678 72.6016H93.2344"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M60.5781 59.2422H93.2344"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M68 85.9609H93.2344"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M47.0703 92.3438L42.6172 87.8906"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M47.0703 92.3438L57.4609 81.9531"
                      stroke="black"
                      stroke-width="3"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </p>
            </div>
            <div class="box-content">
              <p>Plan</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-section-container" id="aboutUsSection">
    <div class="fixed-container">
      <h2>Discover how YOU can add 5-10 more closing per year!</h2>

      <div class="card-row-container">
        <div class="column">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center justify-content-center card-img-main">
              <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.2" x="1" y="1" width="106" height="106" stroke="#231F20" stroke-width="2" />
                <path
                  d="M89.4946 54.0001C89.4946 54.0001 75.3079 36.8792 54.0001 36.8792C32.6923 36.8792 18.5056 54.0001 18.5056 54.0001C18.5056 54.0001 32.6923 71.121 54.0001 71.121C75.3079 71.121 89.4946 54.0001 89.4946 54.0001Z"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.583 53.8692C36.583 63.4225 44.3277 71.1672 53.881 71.1672C63.4343 71.1672 71.179 63.4225 71.179 53.8692C71.179 44.3159 63.4232 36.5823 53.881 36.5823C44.3277 36.5823 36.583 44.327 36.583 53.8692Z"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.5546 53.8691C48.5546 56.8094 50.9401 59.1949 53.8804 59.1949C56.8208 59.1949 59.2063 56.8094 59.2063 53.8691C59.2063 50.9287 56.8208 48.5432 53.8804 48.5432C50.9401 48.5432 48.5546 50.9287 48.5546 53.8691Z"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M53.8801 18.3634V23.545"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.5828 21.6218L39.5342 26.8035"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M71.1665 21.6218L68.2151 26.8035"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M53.8801 89.3751V84.1934"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M71.1665 86.1164L68.2151 80.9459"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.5828 86.1164L39.5342 80.9347"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="13.3333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3>Get More Clarity</h3>
            <p>Our business assessment will provide you more clarity about your business and your goals!</p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center justify-content-center card-img-main">
              <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_930_1124" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="76" height="76">
                  <path d="M76 -3.05176e-05H-3.05176e-05V76H76V-3.05176e-05Z" fill="white" />
                </mask>
                <g mask="url(#mask0_930_1124)">
                  <path
                    d="M2.22633 27.6724C2.22633 41.726 13.6186 53.1198 27.6722 53.1198C33.012 53.1198 37.9679 51.4742 42.0618 48.6619C44.647 46.887 46.8869 44.6471 48.6616 42.0619C51.4739 37.968 53.1196 33.0123 53.1196 27.6724C53.1196 13.6187 41.7259 2.22645 27.6722 2.22645C13.6186 2.22645 2.22633 13.6187 2.22633 27.6724Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M44.2136 27.6732C44.2136 18.5383 36.8079 11.1328 27.6731 11.1328"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M67.4757 73.7734L73.7734 67.4758L56.2678 49.9701L49.9701 56.2678L67.4757 73.7734Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M45.6654 45.6655L53.1184 53.12"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
            <h3>Get More Focus</h3>
            <p>Our business assessment will show you exactly what activities you should focus on to grow your business</p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center justify-content-center card-img-main">
              <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_930_1188" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="76" height="76">
                  <path d="M0 -3.05176e-05H76V76H0V-3.05176e-05Z" fill="white" />
                </mask>
                <g mask="url(#mask0_930_1188)">
                  <path
                    d="M38 55.9609C38 65.7985 30.0251 73.7734 20.1875 73.7734C10.35 73.7734 2.375 65.7985 2.375 55.9609C2.375 46.1234 10.35 38.1484 20.1875 38.1484C30.0251 38.1484 38 46.1234 38 55.9609Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.1875 73.7734H69.1719C71.6312 73.7734 73.625 71.7796 73.625 69.3203V17.0707L58.7819 2.22653H24.6406C22.1813 2.22653 20.1875 4.22035 20.1875 6.67966V38.1484"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M58.7812 2.22656V17.0703H73.625L58.7812 2.22656Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.9678 42.6016H63.2344"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.5781 29.2422H63.2344"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38 55.9609H63.2344"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.0703 62.3438L12.6172 57.8906"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.0703 62.3438L27.4609 51.9531"
                    stroke="black"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
            <h3>Get Your Plan</h3>
            <p>Once complete, the system will deliver you a PDF of YOUR personalized plan for growth!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section2-container">
    <div class="fixed-container">
      <div class="section_content text-center">
        <h2>A Special Message from me to YOU!</h2>
        <div class="bottom-imgVideo" #videoTemplate2></div>

        <ng-container *ngIf="!videoUrl || videoUrl == ''">
          <div class="bottom-imgVideo">
            <img [src]="imageUrl" alt="img" onerror="this.src='/assets/images/landingpage/landing-banner.svg'" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!--<div class="landing-contactus-container">
        <div class="contactus-left-container">
            <img loading="lazy" src="/assets/images/landingpage/customer-service-operator-talking-phone-office 1.svg" alt="">
        </div>
        <div class="contactus-form-container">
            <div class="contact-form">
                <h3>Contact Us</h3>
                <div class="contact-input-div">
                    <img loading="lazy" src="/assets/images/landingpage/user.svg">
                    <input type="text" name="name" placeholder="Enter user name">
                </div>
                <div class="contact-input-div">
                    <img loading="lazy" src="/assets/images/landingpage/email.svg">
                    <input type="email" name="email" placeholder="Enter email">
                </div>
                <div class="contact-input-div">
                    <img loading="lazy" src="/assets/images/landingpage/phone.svg">
                    <input type="tel" id="phone" name="phone" placeholder="Enter phone number">
                </div>
                <div class="contact-input-div">
                    <textarea id="w3review" name="w3review" placeholder="Enter your message here..."></textarea>
                </div>
                <div class="contact-submit-button">
                    <button type="submit" class="submit-button">Submit</button>
                </div>
            </div>
        </div>
    </div>-->
  <!--    <div class="faq-wrapper">
      <div class="fixed-container">
        <div class="faq-top">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="faq-bottom">
             <div class="faq">
        <div class="question-list-main">          
        <ng-container *ngFor="let faq of allFAQ">
          <div
            class="question-list"
            [class]="{ 'active-question': faq.active }"
          >
            <div
              (click)="onSectionChange(faq.id)"
              class="question-list-head d-flex flex-wrap align-items-center justify-content-between"
            >
              <h3>{{ faq.question }}</h3>
              
              <div class="d-flex align-items-center">
                
              
                <a class="down-arrow" href="javascript:void(0);"
                  ><img src="/assets/images/down-arrow.svg"
                /></a>                
              </div>
            </div>
            <div
              class="question-list-content-wrapper"
              [class]="{ 'd-none': !faq.active }"
            >
              <ng-container
              >
               
                  <div class="question-list-content">
                  
                    <div class="question-hint d-flex flex-wrap">
                      
                      <p class="word-break">
                          {{faq.answer}}
                      </p>
                    </div>
                  
                  </div>
              </ng-container>
           
            </div>
          </div>
        </ng-container>
      </div>
    </div>
        </div>
      </div>
    </div>-->

  <div class="footer">
    <div class="header-logo">
      <img loading="lazy" src="/assets/images/landingpage/landing-logo.svg" />
    </div>
    <p>© 2023 The Originators Guide LLC. All Rights Reserved.</p>
    <!-- <div class="social-button">
      <img loading="lazy" src="/assets/images/landingpage/Group 111.svg" />
      <img loading="lazy" src="/assets/images/landingpage/linkdin.svg" />
      <img loading="lazy" src="/assets/images/landingpage/insta.svg" />
      <img loading="lazy" src="/assets/images/landingpage/youtube.svg" />
    </div> -->
  </div>
</div>
