<div class="primary-bg min-100" #sectionHome>
  <section class="nav-sectoin">
    <div class="container">
      <div class="nav-bar d-flex justify-content-end align-item-center" [ngClass]="{active: isShowNavbar}">
        <!-- <div class="menu-btn" (click)="toggleNavbar()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3579_7590)">
              <path d="M20 6H4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 12H4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 18H4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_3579_7590">
                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
              </clipPath>
            </defs>
          </svg>
        </div> -->
        <!-- <div class="menu-list-container">
          <ul class="d-flex align-item-center menu-list list-style-none">
            <ng-container *ngFor="let item of menuItems; let i = index">
              <li class="list" [ngClass]="{active: item.isActive}" (click)="changeSection(item?.sectionName, i)">
                {{ item?.text }}
              </li>
            </ng-container>
          </ul>
        </div> -->
        <div class="social-wrapper">
          <ul class="d-flex align-item-center social-links list-style-none">
            <li class="link mr2">
              <a href="https://www.facebook.com/timwdavis" target="_blank"><img src="assets/images/icon/facebook.svg" alt="" /></a>
            </li>
            <!-- <li class="link mr2">
              <a href="#"><img src="assets/images/icon/twitter.svg" alt="" /></a>
            </li> -->
            <li class="link">
              <a href="https://www.instagram.com/tim_w_davis/" target="_blank"><img src="assets/images/icon/instagram.svg" alt="" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="banner-wrapper">
    <div class="container">
      <div class="d-flex align-item-center wrapper-content flex-wrap">
        <!-- <div class="left-wrapper">
          <img src="assets/images/bg/model.png" alt="" />          
        </div> -->
        <div class="heading">
          <h1 class="text-center f-42-700 text-white mb-3 f-25-767">
            MORTGAGE<span class="text-color-pre f-42-700 f-25-767">PROFITPLAN</span>
          </h1>
          <h2 class="text-center f-30-700 text-white mb-3 f-25-767">Ready to Run Your Mortgage Business Your Way?</h2>
          <!-- <p class="text-center">In Less Than 15 Minutes, You Will Discover How Much More Money You Can Make Running Your Own P&L!</p> -->
          <p class="text-center">Discover how to earn more, work smarter, and build your own brand with the Solopreneur P&L model</p>
          <div class="heading-video" style="max-width: 766px">
            <iframe
              src="https://player.vimeo.com/video/994833811?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="430px"
              frameborder="1"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Full Edited"
            ></iframe>
            <!-- <video controls>
              <source src="assets/videos/movie.mp4" type="video/mp4" />
            </video> -->
          </div>
          <div class="text-center">
            <button type="button" class="primary-btn" [routerLink]="'/account/signup'">Click to Begin!</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="p5 about-us-section" #sectionAboutUs>
    <div class="container">
      <div class="d-flex align-item-center">
        <div class="about-us-left about-us-box">
          <div>
            <img src="assets/images/bg/about-us.png" alt="" class="w-100" />
          </div>
        </div>
        <div class="about-us-right about-us-box pl-2">
          <div class="about-us-righ-content">
            <h2 class="h2">About Us</h2>
            <p class="primary-font-color">
              Lorem ipsum dolor sit amet consectetur Dui id massa enim nisl Id id vel nisl non habitant quis amet ut du Turpis et placerat
              vel fringilla Leo commodo vel eget pulvinar Lorem ipsum dolor sit amet consectetur Dui id massa enim nisl tortor Id id vel
              nisl non habitant quis amet ut du Turpis et placerat vel fringilla Leo commodo vel eget pulvinar Lorem ipsum dolor sit amet
              consectetur Dui id massa enim nisl tortor Id id vel nisl non habitant quis amet ut du Turpis et placerat vel fringilla Leo
              commodo vel eget pulvinar
            </p>
            <button class="primary-btn">Read More</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="portfolio-section p5" #sectionPortfolio>
    <div class="container">
      <div class="heading d-flex align-item-center justify-spacebetween">
        <div class="heading-left">
          <h2 class="h2">Portfolio</h2>
        </div>
        <div class="heading-right">
          <p class="primary-font-color">
            Lorem ipsum dolor sit amet consectetur Dui id massa enim nisl Id id vel nisl non habitant quis amet ut du Turpis et placerat vel
            fringilla Leo commodo vel eget pulvinar
          </p>
        </div>
      </div>
      <div class="portfolio-wrapper">
        <div class="wrapper-content d-flex">
          <div class="content-box">
            <div><img src="assets/images/bg/portfolio.png" alt="" /></div>
            <div class="content-box-bottom">
              <h3>Lorem ipsum</h3>
              <div class="d-flex align-item-center">
                <a href="#" class="mr3 secondery-btn">Lorem</a><a href="#" class="secondery-btn">Lorem ipsum</a>
              </div>
            </div>
          </div>
          <div class="content-box">
            <div><img src="assets/images/bg/portfolio.png" alt="" /></div>
            <div class="content-box-bottom">
              <h3>Lorem ipsum</h3>
              <div class="d-flex align-item-center">
                <a href="#" class="mr3 secondery-btn">Lorem</a><a href="#" class="secondery-btn">Lorem ipsum</a>
              </div>
            </div>
          </div>
          <div class="content-box">
            <div><img src="assets/images/bg/portfolio.png" alt="" /></div>
            <div class="content-box-bottom">
              <h3>Lorem ipsum</h3>
              <div class="d-flex align-item-center">
                <a href="#" class="mr3 secondery-btn">Lorem</a><a href="#" class="secondery-btn">Lorem ipsum</a>
              </div>
            </div>
          </div>
          <div class="content-box">
            <div><img src="assets/images/bg/portfolio.png" alt="" /></div>
            <div class="content-box-bottom">
              <h3>Lorem ipsum</h3>
              <div class="d-flex align-item-center">
                <a href="#" class="mr3 secondery-btn">Lorem</a><a href="#" class="secondery-btn">Lorem ipsum</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer-section">
    <div class="container">
      <div class="footer-wrapper">
        <p class="primary-font-color">© Copyright 2022, All Rights Reserved by ClarityUI</p>
      </div>
    </div>
  </section> -->
</div>
