<!-- <section>
  <div class="sign-up-wrapper d-flex flex-wrap align-items-center">
    <div class="sign-up-left w-50 text-center height-sidebar-100">
      <img [src]="logoPath" alt="logo" />
      <img src="/assets/images/signup-bottom.svg" alt="img" class="bottom-img" />
    </div>
    <div class="sign-up-right w-50">
      <div class="sign-up-right-wrapper">
        <div class="mobile-logo text-center">
          <img [src]="logoPath" alt="logo" />
        </div>
        <h1 class="main-heading text-center">Login</h1>
        <form [formGroup]="form"> -->
<!-- <div class="form-control-main position-relative">    
                        <span>
                            <img src="/assets/images/user-icon.svg" />
                        </span>
                        <input type="text" class="form-control" placeholder="Enter user name">
                    </div> -->
<!-- <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/user-email.svg" />
            </span>
            <input formControlName="email" type="email" class="form-control" placeholder="Enter email" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
            >
              <div *ngIf="form.controls['email'].errors?.required">Please Enter Email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please Enter valid Email.</div>
            </div>
          </div>

          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/eye-fill.svg" />
            </span>
            <input formControlName="password" type="password" class="form-control" placeholder="Password" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)"
            >
              <div *ngIf="form.controls['password'].errors?.required">Please Enter password.</div>
            </div>
          </div>

          <div class="form-control-main text-right">
            <a class="forget-password" (click)="onForgotUpClick()" href="javascript:void(0);">Forgot Password?</a>
          </div>
          <div class="form-control-main sign-in-btn text-center">
            <button (click)="onSubmit()">Login</button>
          </div>
        </form>
        <p class="text-center" *ngIf="!isAgent">
          Doesn't have an account?
          <a (click)="onSignUpClick()" href="javascript:void(0);">Sign Up Here</a>
        </p>

        <p class="text-center" *ngIf="isAgent">
          Are You a Real Estate Agent Who Would Like to Learn How to Grow Your Business? If So
          <a (click)="onInquiryClick()" href="javascript:void(0);">Click Here</a>
          <br />or
          <a (click)="onSignUpClick()" href="javascript:void(0);">Sign Up Here</a>
        </p>
      </div>
    </div>
  </div>
</section> -->

<section>
  <div class="pre-signup-wrapper d-flex flex-wrap bg-main align-items-center mh-100vh">
    <div class="d-flex flex-wrap pre-signup-left w-40 bg-secondary-only mh-100vh align-items-end d-none-991">
      <img src="assets/images/bg/team-davis.png" />
    </div>
    <div class="d-flex flex-wrap pre-signup-right w-60 mh-100vh align-items-center bg-img-991 w-100-991">
      <div class="pre-signup-right-inner">
        <h2 class="f-42-700 text-white mb-70 f-25-767 mb-30-767">
          MORTGAGE<span class="text-color-pre f-42-700 f-25-767">PROFITPLAN</span>
        </h2>
        <h3 class="f-40-600 text-white mb-76 f-25-767 mb-30-767">Login</h3>
        <form [formGroup]="form" class="d-flex flex-wrap" (ngSubmit)="onSubmit()">
          <div class="w-50 pr-3 mb-3">
            <p class="f-16-400 text-white mb-1">Email</p>
            <input type="text" name="email" placeholder="Email" class="pre-input" formControlName="email" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
            >
              <div *ngIf="form.controls['email'].errors?.required">Please enter email</div>
              <div *ngIf="form.controls['email'].errors?.email">Please enter valid email</div>
            </div>
          </div>
          <div class="w-50 mb-5">
            <p class="f-16-400 text-white mb-1">Password</p>
            <input type="password" name="password" placeholder="Password" class="pre-input" formControlName="password" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)"
            >
              <div *ngIf="form.controls['password'].errors?.required">Please enter password</div>
              <div *ngIf="form.controls['password'].errors?.minlength">Password must be 8 character long</div>
            </div>
          </div>
          <div class="form-control-main text-right">
            <a class="forget-password" (click)="onForgotUpClick()">Forgot Password?</a>
          </div>
          <div class="w-100 mb-3">
            <button type="submit" class="pre-sign-btn cursor-pointer">Login</button>
          </div>
          <div class="w-100 text-center">
            <p class="f-16-400 text-color1-pre">
              Doesn't have an account?
              <a class="f-16-600 text-white" [routerLink]="'/account/signup'">Sign Up Here</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
