import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { accountRoutes, appRoute } from 'src/app/Core/routes.enum';
import { Storage } from 'src/app/Core/storage';
import { AccountService } from 'src/app/services/account.service';
import { IUser } from 'src/app/Models/user.interface';
import { ToastService } from 'src/app/services/toast.service';
import { LoaderService } from 'src/app/shared/Services/loader.service';
import { SubSink } from 'src/app/Core/SubSink';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  private subSink = new SubSink();

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private account: AccountService,
    private toaster: ToastService,
    private loader: LoaderService
  ) { }

  ngOnInit() {
    Storage.removeUserData();
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  onForgotUpClick() {
    this.route.navigateByUrl(accountRoutes.account + '/' + accountRoutes.forgotpassword);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.loader.open();

    this.subSink.sink = this.account.preProfitLogin(this.form.value).subscribe({
      next: data => {
        if (data) {
          this.loader.close();
          Storage.setUserData(data);
          Storage.setToken(data?.accessToken);
          this.toaster.success({
            title: 'Login',
            message: 'Login Successful',
            timeout: 2000
          });
          this.route.navigate([appRoute.pages + '/' + appRoute.assessment]);
        }
      },
      error: err => {
        this.loader.close();
        this.toaster.error({
          title: 'Login Failed',
          message: err?.error?.errorMessage || err?.errorMessage || 'Some thing went wrong',
          timeout: 2000
        });
      }
    })
  }

  // ngOnDestroy(): void {
  //   this.subSink.unSubscribe();
  // }

  // private subSink = new SubSink();

  // form = new FormGroup({
  //   email: new FormControl('', [Validators.email, Validators.required]),
  //   password: new FormControl('', [Validators.required, Validators.min(8)])
  // });
  // logoPath = '/assets/images/signup-logo.svg';

  // isAgent = environment.isAgent;

  // timUri: string = environment.adminUri;

  // constructor(private route: Router, private account: AccountService, private toaster: ToastService, private loader: LoaderService) {
  //   if (environment.isAgent) {
  //     this.logoPath = '/assets/images/Mortagage_Agent_Logo.svg';
  //   }
  // }

  // ngOnInit(): void {
  //   Storage.removeUserData();
  // }

  // onSubmit() {
  //   this.form.markAllAsTouched();
  //   if (!this.form.valid) {
  //     return;
  //   }

  //   let formValue = this.form.value;

  //   if (!formValue) {
  //     return;
  //   }

  //   this.loader.open();
  //   this.subSink.sink = this.account.login(formValue.email ?? '', formValue.password ?? '').subscribe({
  //     next: (data: IUser) => {
  //       this.loader.close();
  //       if (data && data != undefined && data != null) {
  //         Storage.setUserData(data);

  //         Storage.setToken(data.token);
  //         if (environment.isAgent) {
  //           this.route.navigateByUrl(appRoute.pages + '/' + appRoute.profitofficer);
  //         } else {
  //           this.route.navigateByUrl(appRoute.pages + '/' + appRoute.assessment);
  //         }
  //       } else {
  //         this.toaster.error({
  //           title: 'Login Failed',
  //           message: 'User Name or Password is incorrect',
  //           timeout: 2000
  //         });
  //       }
  //     },
  //     error: (err: any) => {
  //       this.loader.close();
  //       this.toaster.error({
  //         title: 'Login Failed',
  //         message: err?.error?.errorMessage || err?.errorMessage || 'Some thing went wrong',
  //         timeout: 2000
  //       });
  //     },
  //     complete: () => {
  //       this.loader.close();
  //     }
  //   });
  // }

  // onSignUpClick() {
  //   if (this.isAgent) {
  //     this.route.navigateByUrl(accountRoutes.account + '/' + accountRoutes.profitOfficerSignup);
  //   } else {
  //     this.route.navigateByUrl(accountRoutes.account + '/' + accountRoutes.signup);
  //   }
  // }

  // onInquiryClick() {
  //   this.route.navigateByUrl(this.timUri);
  // }

  // onForgotUpClick() {
  //   this.route.navigateByUrl(accountRoutes.account + '/' + accountRoutes.forgotpassword);
  // }
}
