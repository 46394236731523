import { Injectable } from '@angular/core';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  pageSize: number = 10240;
  private coinSound: Howl;

  constructor() {
    this.coinSound = new Howl({
      src: ['/assets/sound/mixkit-score-casino-counter-1998.wav'], 
    });
  }

  private delay(timeOut: number = 2000) {
    return new Promise<boolean>((res) => {
      setTimeout(() => {
        res(true);
      }, timeOut);
    });
  }

  private async createToast(
    config: {
      title: string;
      message: string;
      timeout: number;
    },
    type: 'info' | 'warning' | 'success' | 'error'
  ) {
    let typeClass = '';
    switch (type) {
      case 'info':
        typeClass = 'toast-info';
        break;
      case 'error':
        typeClass = 'toast-error';
        break;
      case 'success':
        typeClass = 'toast-success';
        break;
      case 'warning':
        typeClass = 'toast-warning';
        break;
    }

    let toast = `
        <div id="toast-container" class="toast-top-right toast-container" *ngIf="isTrue">
            <div class="ng-tns-c11-11 ng-star-inserted ng-trigger ng-trigger-flyInOut toastr ${typeClass}"
                toast-component="" style="opacity: 1;">
                <div class="ng-tns-c11-11 toast-title ng-star-inserted" aria-label="roahn" style="">
                  ${config.title}
                </div>

                <div role="alert" class="ng-tns-c11-11 toast-message ng-star-inserted" aria-label="sdfdsfsdds">
                    ${config.message}
                </div>
            </div>
        </div>
    `;

    let elm = document.createElement('div');
    elm.innerHTML = toast;

    let body = document.getElementsByTagName('body')[0];
    body.appendChild(elm);

    await this.delay(config.timeout);

    body.removeChild(elm);
  }

  private close() {}
  async info(config: {title: string; message: string; timeout: number}) {
    this.createToast(config, 'info');
  }

  async warning(config: {title: string; message: string; timeout: number}) {
    this.createToast(config, 'warning');
  }

  async error(config: {title: string; message: string; timeout: number}) {
    this.createToast(config, 'error');
  }

  async success(config: {title: string; message: string; timeout: number}) {
    this.createToast(config, 'success');
  }  
  playCoinSound() {
    this.coinSound.play();
  }
}
