<!-- 
  after image tag line 7 to 14 
<ng-container *ngIf="videoUrl && videoUrl != ''">


  <iframe width="560" height="315" [src]="videoUrl" title="YouTube video player" frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>

</ng-container> -->
<!-- <section [class]="{'url-agent-wrapper': parentUri}">
  <div class="sign-up-wrapper d-flex flex-wrap align-items-center">
    <div class="sign-up-left w-50 text-center height-sidebar-100">
      <img [src]="logoPath" alt="logo" />


      <div #videoTemplate></div>

      <ng-container *ngIf="!videoUrl || videoUrl == ''">
        <img [src]="imageUrl" alt="img" class="bottom-img" onerror="this.src='/assets/images/signin-bottom.svg'" />
      </ng-container>
    </div>
    <div class="sign-up-right w-50">
      <div class="sign-up-right-wrapper">
        <div class="mobile-logo text-center">
          <img [src]="logoPath" alt="logo" />
        </div>
        <h1 class="main-heading text-center">Sign Up</h1>
        <ng-container *ngIf="parentUri">
          <div class="your-profit-adviser">
            <p>
              <i><img src="/assets/images/officer.svg" /></i>
              Your Profit Adviser:
            </p>
            <label>{{ user.name }}</label>
          </div>
        </ng-container>
        <form [formGroup]="form">
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/user-icon.svg" />
            </span>
            <input formControlName="name" type="text" class="form-control" placeholder="Enter user name" appTrimInput />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)"
            >
              <div *ngIf="form.controls['name'].errors?.required">Please enter name.</div>
            </div>
          </div>

          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/user-email.svg" />
            </span>
            <input formControlName="email" type="email" class="form-control" placeholder="Enter email" appTrimInput />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
            >
              <div *ngIf="form.controls['email'].errors?.required">Please enter email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please enter valid email.</div>
            </div>
          </div>
          <div class="form-control-main1 position-relative">
            <span>
              <img src="/assets/images/call-icon.svg" />
            </span>
            <ngx-intl-tel-input
              [cssClass]="'custom'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [separateDialCode]="separateDialCode"
              [searchCountryField]="[SearchCountryField.All]"
              [preferredCountries]="preferredCountries"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.UnitedStates"
              [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.National"
              name="mobileNo"
              formControlName="mobileNo"
            >
            </ngx-intl-tel-input>
            <div class="invalid-feedback" *ngIf="form.controls['mobileNo'].invalid && form.controls['mobileNo'].touched">
              <div *ngIf="form.controls['mobileNo'].errors?.required; else errorShow">Please enter mobile number.</div>
              <ng-template #errorShow>
                <div *ngIf="form.controls['mobileNo'].errors">Please enter valid mobile number.</div>
              </ng-template>
            </div>
          </div>

          <div class="login-select">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/globalWhite.svg" class="ImageRemix" />
              </span>
              <input
                formControlName="country"
                id="country"
                placeholder="please enter your Country"
                type="text"
                class="form-control"
                alphaNumeric
              />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['country'].invalid && (form.controls['country'].dirty || form.controls['country'].touched)"
              >
                <div *ngIf="form.controls['country'].errors?.required">Please select country</div>
              </div>
            </div>
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/StateWhite.svg" class="ImageRemix" />
              </span>
              <select class="form-control" id="state" [formControl]="state" name="state">
                <option value="" selected disabled>
                  {{ form.controls['state'].status === 'DISABLED' ? 'No state available' : 'Select State' }}
                </option>
                <option *ngFor="let state of states" [value]="state">
                  {{ state }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)"
              >
                <div *ngIf="form.controls['state'].errors?.required">Please select state</div>
              </div>
            </div>
          </div>

          <div class="login-select">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/cityWhite.svg" class="ImageRemix" />
              </span>
              <input
                formControlName="city"
                id="city"
                placeholder="Enter your city"
                type="text"
                class="form-control"
                alphaNumeric
                appTrimInput
              />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)"
              >
                <div *ngIf="form.controls['city'].errors?.required">Please enter city</div>
              </div>
            </div>
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/pincodewhite.svg" class="ImageRemix" />
              </span>
              <input
                formControlName="pinCode"
                type="number"
                class="form-control"
                placeholder="Enter your zipcode"
                appNoDot
                maxLength="6"
                onKeyPress="if(this.value.length==6) return false;"
                min="0"
                max="999999"
              />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['pinCode'].invalid && (form.controls['pinCode'].dirty || form.controls['pinCode'].touched)"
              >
                <div *ngIf="form.controls['pinCode'].errors?.required">Please enter zipCode</div>
              </div>
            </div>
          </div>
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/location-icon.svg" />
            </span>
            <input formControlName="address" type="text" class="form-control" placeholder="Enter your address" appTrimInput />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)"
            >
              <div *ngIf="form.controls['address'].errors?.required">Please enter address.</div>
            </div>
          </div>

          <ng-container *ngIf="isPasswords">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/eye-fill.svg" />
              </span>
              <input formControlName="password" type="password" class="form-control" placeholder="Enter your password" minlength="8" />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)"
              >
                <div *ngIf="form.controls['password'].errors?.required">Please enter password.</div>
                <div *ngIf="form.controls['password'].errors?.minlength">Please enter at least 8 character.</div>
              </div>
            </div>
          </ng-container>
          <div class="form-control-main sign-in-btn text-center">
            <button (click)="onSubmit()">Sign Up</button>
          </div>
        </form>
        <p class="text-center">
          Have an account ?
          <a (click)="onLoginClick()" href="javascript:void(0);">Login Here</a>
        </p>
      </div>
    </div>
  </div>
</section> -->

<section>
  <div class="pre-signup-wrapper d-flex flex-wrap bg-main align-items-center mh-100vh">
    <!-- <div class="d-flex flex-wrap pre-signup-left w-40 bg-secondary-only mh-100vh align-items-end d-none-991">
      <img src="assets/images/bg/team-davis.png" />
    </div> -->
    <div class="d-flex flex-wrap pre-signup-right w-100 mh-100vh align-items-center bg-img-991 w-100-991">
      <div class="pre-signup-right-inner">
        <h2 class="f-42-700 text-white mb-70 f-25-767 mb-30-767">
          MORTGAGE<span class="text-color-pre f-42-700 f-25-767">PROFITPLAN</span>
        </h2>
        <h3 class="f-40-600 text-white mb-76 f-25-767 mb-30-767">Sign Up</h3>
        <form [formGroup]="form" class="d-flex flex-wrap">
          <div class="w-50 pr-3 mb-3">
            <p class="f-16-400 text-white mb-1">First Name</p>
            <input type="text" name="firstName" placeholder="First Name" class="pre-input" formControlName="firstName" />

            <div
              class="invalid-feedback"
              *ngIf="form.controls['firstName'].invalid && (form.controls['firstName'].dirty || form.controls['firstName'].touched)"
            >
              <div *ngIf="form.controls['firstName'].errors?.required">Please enter first name.</div>
              <div *ngIf="form.controls['firstName'].errors?.noLeadingTrailingSpaces">Leading or trailing spaces are not allowed.</div>
            </div>
          </div>
          <div class="w-50 mb-3">
            <p class="f-16-400 text-white mb-1">Last Name</p>
            <input type="text" name="lastName" placeholder="Last Name" class="pre-input" formControlName="lastName" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['lastName'].invalid && (form.controls['lastName'].dirty || form.controls['lastName'].touched)"
            >
              <div *ngIf="form.controls['lastName'].errors?.required">Please enter last name.</div>
              <div *ngIf="form.controls['lastName'].errors?.noLeadingTrailingSpaces">Leading or trailing spaces are not allowed.</div>
            </div>
          </div>
          <div class="w-50 pr-3 mb-3">
            <p class="f-16-400 text-white mb-1">Email</p>
            <input type="text" name="email" placeholder="Email" class="pre-input" formControlName="email" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
            >
              <div *ngIf="form.controls['email'].errors?.required">Please enter email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please enter valid email.</div>
            </div>
          </div>
          <div class="w-50 mb-3">
            <p class="f-16-400 text-white mb-1">Phone Number</p>
            <input type="number" name="mobileNumber" placeholder="Phone Number" class="pre-input" formControlName="mobileNumber" />
            <div
              class="invalid-feedback"
              *ngIf="
                form.controls['mobileNumber'].invalid && (form.controls['mobileNumber'].dirty || form.controls['mobileNumber'].touched)
              "
            >
              <div *ngIf="form.controls['mobileNumber'].errors?.required">Please enter phone number.</div>
              <div *ngIf="form.controls['mobileNumber'].errors?.pattern">Please enter valid phone number.</div>
            </div>
          </div>
          <div class="w-100 mb-5">
            <p class="f-16-400 text-white mb-1">Password</p>
            <input type="password" name="password" placeholder="Password" class="pre-input" formControlName="password" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)"
            >
              <div *ngIf="form.controls['password'].errors?.required">Please enter password.</div>
              <div *ngIf="form.controls['password'].errors?.minlength">Please enter at least 8 character.</div>
            </div>
          </div>
        </form>
        <div class="w-100 mb-5">
          <div class="d-flex text-white">
            <div class="popup-custom-checkbox">
              <label class="container text-justify">
                By checking this box, I consent to be contacted by email, telephone and/or sms messages using manual and automated
                technology, even if my number is currently listed on the National do not call list. I understand that message and data rates
                may apply and that I may reply STOP to opt-out of future messaging: reply HELP for additional messaging help. Message
                frequency may vary depending on interaction between you and our agents. See our privacy policy here for more details.
                <input type="checkbox" [(ngModel)]="isSmsTCP" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="w-100 mb-3">
          <button
            type="button"
            class="pre-sign-btn"
            [ngClass]="{'cursor-pointer': isSmsTCP, disabled: !isSmsTCP}"
            (click)="onSubmit()"
            [disabled]="!isSmsTCP"
          >
            Sign Up
          </button>
        </div>
        <div class="w-100 text-center">
          <p class="f-16-400 text-color1-pre">
            Already a member?
            <a class="f-16-600 text-white" [routerLink]="'/account/login'">Sign In</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
