import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[inputWithComma]',
  providers: [DecimalPipe],
})
export class NumberCommaDirective implements OnInit, OnDestroy {
  @Input() validationFieldsType: string | undefined;

  constructor(private el: ElementRef, private decimal: DecimalPipe) {}

  @HostListener('keypress', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.validateFields(event);
  }

  // @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent) {
  //   this.validateFields(event);
  // }
  ngOnInit(): void {}
  ngOnDestroy(): void {}

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event: KeyboardEvent) {
    setTimeout(() => {
      const parts = this.el.nativeElement.value.toString().split('.');
      parts[0] = this.decimal.transform(parts[0].replace(/,/g, ''));
      this.el.nativeElement.value = parts.join('.');
      // this.el.nativeElement.value =
      //   CommonFunction.removeSpecialCharacterFromString(
      //     this.el.nativeElement.value
      //   );

      event.preventDefault();
    }, 100);
  }
}
