export enum accountRoutes {
  account = 'account',
  login = 'login',
  welcome = 'welcome',
  signup = 'signup',
  profitOfficerSignup = 'profitadvisersignup',
  forgotpassword = 'forgotpassword',
  resetpassword = 'resetpassword',
  resetpasswordafterverification = 'resetpasswordafterverification',
  sendotp = 'sendotp',
  verifyotp = 'verifyotp',
  eventSignup = 'eventsignup',
  agentSignup = 'signup',
  verifyAccount = 'verifyaccount',
  thanks = 'thanks',
  agentSignUp2 = 'agentsignup',
  agentWelcome = 'agentwelcome',
  agentLanding = 'agentlanding'
}

export enum appRoute {
  pages = 'pages',
  appoitments = 'appoitments',
  eventSchedule = 'scheduleevent',
  questionConfig = 'questionconfig',
  profitofficer = 'profitofficer',
  assessment = 'assessment',
  newAssessment = 'newassessment',
  agentAssessment = 'agentassessment',
  changepassword = 'changepassword',
  userprofile = 'userprofile',
  downloadPdf = 'downlaodpdf',
  inquiry = 'inquiry',
  poInquiry = 'poinquiry',
  agents = 'agents',
  faq = 'faq',
  unarchive = 'unarchive',
  adminassessment = 'adminassessment',
  agentProfitPlan = 'agentProfitPlan',
  agentProfitPlanlist = 'agentProfitPlanlist',
  agentProfitScript = 'agentProfitScript',
  agentProfitUses = 'agentProfitUses',
  agentProfitUsesList = 'agentProfitUsesList',
  weeklyPlans = 'weeklyPlans',
  agentScriptCallsClasses = 'agentScriptCallsClasses',
  weeklyPlanSuccessList = 'weeklyPlanSuccessList',
  preAssessment = 'pre-assessment',
  profitPlanReport = 'profit-plan-report'
}
